import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Title from "../components/Title"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO title="Impressum" description={data.site.siteMetadata.description} />

      <StyledHero img={data.geschichteImage.childImageSharp.fluid}></StyledHero>

      <ServicesContainer>
        <Title title="Impressum " subtitle="" />
        <hr className="services_separator" />
        <TextWrapper>
          <h3>Anschrift</h3>
          <p>
            Paul Berenz Bedachungen GmbH
            <br />
            Industriering 21
            <br />
            56812 Cochem-Brauheck
          </p>
          <h3>Kontakt</h3>
          <p>
            Tel.: +49 (0) 26 71 - 48 08
            <br />
            Fax: +49 (0) 26 71 - 42 73
            <br />
            E-Mail: info@paul-berenz.de
            <br />
            URL: www.paul-berenz.de
          </p>
          <h3>Geschäftsführer</h3>
          <p>Thomas Berenz</p>
          <h3>Amtsgericht</h3>
          <p>Amtsgericht Koblenz HRB 4849</p>
          <h3>Kammer</h3>
          <p>
            Handwerkskammer Koblenz
            <br />
            Friedrich-Ebert-Ring 38
            <br />
            56068 Koblenz
          </p>
          <h3>Berufsbezeichnung</h3>
          <p>Dachdeckermeister (Bundesrepublik Deutschland) Dipl.-Ing. (FH)</p>
          <h3>Berufsrechtliche Regelungen</h3>
          <p>
            Gesetz zur Ordnung des Handwerks:{" "}
            <a href="https://www.gesetze-im-internet.de/hwo/">
              https://www.gesetze-im-internet.de/hwo/
            </a>
          </p>

          <h3>Steuernummer</h3>
          <p>45-012-40520-57</p>
        </TextWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    geschichteImage: file(relativePath: { eq: "geschichte.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Index
